import { template as template_ea212a96da9542c4a279d41a9b68dcdb } from "@ember/template-compiler";
const FKText = template_ea212a96da9542c4a279d41a9b68dcdb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
